@import './variables.scss';

/**
  Typography styling tools
 */

.h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
  color: $primary-text-color;
  font-weight: 700;
  margin: 0;
  font-family: $font-family-title;
}

.h1 {
  font-size: 36px;
  line-height: 44px;

  @media(min-width: 768px) {
    font-size: 68px;
    line-height: 76px;
  }

  @media(min-width: 1024px) {
    font-size: 96px;
    line-height: 104px;
  }
}

.h2 {
  font-size: 32px;
  line-height: 40px;

  @media(min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media(min-width: 1024px) {
    font-size: 60px;
    line-height: 68px;
  }
}

.h4 {
  font-size: 24px;
  line-height: 32px;

  @media(min-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }
}

.h5 {
  font-size: 20px;
  line-height: 26px;

  @media(min-width: 1024px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.h6 {
  font-size: 18px;
  line-height: 24px;

  @media(min-width: 1024px) {
    font-size: 20px;
    line-height: 26px;
  }
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $primary-text-color;
}

picture {
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &.bordered {
    img {
      border-radius: $border-radius;
    }
  }
}

figure {
  margin: 0;
}

.fix-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.columns2 {
  display: flex;
  flex-direction: column;


  @media(min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }

  .text-photo__text {
    grid-column: 1;
  }

  .text-photo__image {
    grid-column: 2;
    border-left: 1px solid $border-color;
  }

  &--reverse {
    .text-photo__image {
      border-left: 0;

      @media(min-width: 1024px) {
        border-right: 1px solid $border-color;
      }
    }

    @media(min-width: 1024px) {
      flex-direction: row-reverse;
    }
  }

  &__block {
    &:first-child {
      padding: 24px 12px 12px;
    }

    &:last-child {
      padding: 12px 12px 24px;
    }

    @media(min-width: 768px) {
      &:first-child {
        padding: 48px 64px 24px;
      }

      &:last-child {
        padding: 24px 64px 48px;
      }
    }

    @media(min-width: 1024px) {
      align-items: center;
      &:first-child,
      &:last-child {
        padding: 24px;
      }
    }

    &.text-photo__text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 24px;

      @media(min-width: 1024px) {
        gap: 48px;
        max-width: 590px;
      }
    }
  }
}


/**
  Grid Cards
 */

.info-grid {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  @media(min-width: 768px) {
    padding: 0 64px;
  }

  @media(min-width: 1024px) {
    padding: 0;
  }

  .fix-container {
    display: grid;
    grid-template-columns: 1fr;

    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media(min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 48px;
    }
  }

  p {
    margin: 0;
  }
}

/**
  Cards
 */

.info-grid__card {
  border-bottom: 1px solid $border-color;
  padding: 24px 12px;

  &:first-child {
    border-top: 1px solid $border-color;
  }

  @media(min-width: 768px) {
    border-top: 0;
    border-left: 1px solid $border-color;
    border-bottom: 0;

    &:nth-child(2n+2) {
      border-right: 1px solid $border-color;
      border-left: 0;
    }
  }

  @media(min-width: 1024px) {
    padding: 24px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    border-left: 1px solid $border-color;

    &:nth-child(2n+2) {
      border-left: 1px solid $border-color;
      border-right: inherit;
    }

    &:nth-child(3n+3) {
      border-right: 1px solid $border-color;
    }
  }

  &-inner {
    border-radius: $border-radius;
    background-color: $bg-color;
    box-sizing: border-box;
    height: 100%;

    &--primary {
      background-color: $primary-bg-color;
    }

    &--secondary {
      background-color: $secondary-bg-color;
    }

    &-image {
     img {
       border-radius: $border-radius $border-radius 0 0;
     }
    }

    &-text {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
      padding: 24px 12px;

      @media(min-width: 1024px) {
        padding: 32px 24px 46px;
        grid-row-gap: 24px;
      }
    }
  }
}

/**
  Features
 */

.features {
  &__title {
    padding: 48px 12px 24px;
    border-top: 1px solid $border-color;

    @media(min-width: 768px) {
      padding: 32px 64px;
    }

    @media(min-width: 1024px) {
      padding: 48px 20px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;

    @media(min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      border: 1px solid $border-color;
      padding: 0 64px;
    }

    @media(min-width: 1024px) {
      padding: 0;
    }
  }
}

.feature {
  &-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    padding: 24px 12px;
    border-top: 1px solid $border-color;
    width: 100%;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    @media(min-width: 768px) {
      border-left: 1px solid $border-color;
      width: calc(100%/2 - 26px);

      &:first-child {
        border-top: 0;
      }

      &:nth-child(2) {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:nth-child(2n+2) {
        border-right: 1px solid $border-color;
      }
    }

    @media(min-width: 1024px) {
      padding: 24px;
      border-top: 0;
      width: calc(100%/4 - 49px);

      &:first-child {
        border-left: 0;
      }

      &:nth-child(2n+2) {
        border-right: 0;
      }
    }

    &__icon {
      img {
        max-width: 37px;
      }
    }

    &__description {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 24px;

      @media(min-width: 1024px) {
        grid-row-gap: 16px;
      }

      .h6 {
        @media(min-width: 1024px) {
          height: 52px;
        }
      }
    }

    p {
      margin: 0;
    }
  }
}

/**
Accent
 */

.accent {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  .fix-container {
    box-sizing: border-box;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    @media(min-width: 768px) {
      padding: 48px 64px;
    }

    @media(min-width: 1024px) {
      padding: 48px 120px;
    }
  }

  .columns2 {
    @media(min-width: 768px) {
      flex-direction: row;
      gap: 32px;
      align-items: center;

      &:last-child {
        border: 0;
      }
    }

    @media(min-width: 1024px) {
      margin: 0;
    }

    &__block {
      @media(min-width: 768px) {
        width: calc(100%/2 - 32px);
      }

      &:first-child {
        padding-top: 48px;

        @media(min-width: 768px) {
          padding: 0;
        }
      }

      &:last-child {
        @media(min-width: 768px) {
          padding: 0;
        }
      }
    }
  }

  &__description {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }

  &__description-text {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }

  &__description-item {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-column-gap: 16px;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .h6 {
      span {
        background-color: $primary-bg-color;
        border-radius: 5px;
        padding: 2px 3px;
        position: relative;
      }
    }
  }
}

/**
Text Block
 */

.text-block {
  border-top: 1px solid $border-color;
  box-sizing: border-box;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  @media(min-width: 1024px) {
    border-bottom: 1px solid $border-color;
    margin: 0 auto;
  }

  .fix-container {
    @media(min-width: 768px) {
      padding: 64px 64px 48px;
    }

    @media(min-width: 1024px) {
      padding: 48px 68px 48px 24px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  .columns2 {
    @media(min-width: 768px) {
      gap: 24px;
    }

    @media(min-width: 1024px) {
      gap: 84px;
    }

    &__block {
      @media(min-width: 768px) {
        padding: 0;
        max-width: 550px;
      }

      @media(min-width: 1024px) {
        width: calc(100%/2);
        max-width: inherit;
      }
    }
  }

  p {
    margin: 0;
  }
}

/**
Text + Pic
 */

.text-pic {
  &__row {
    border-top: 0;
    border-bottom: 1px solid $border-color;

    &:first-child {
      border-top: 1px solid $border-color;
    }

    @media(min-width: 1024px) {
      border-bottom: 1px solid $border-color;
    }
  }

  .columns2 {
    &__block {
      box-sizing: border-box;

      &.text-photo__text {
        max-width: 730px;
      }

      &.text-photo__image {
        max-width: 710px;
      }
    }

    &--reverse {
      .text-photo__text {
        @media(min-width: 1024px) {
          padding-left: 50px;
        }
      }
    }
  }

  p {
    margin: 0;
  }
}

/**
Text List
 */

.text-list {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  ul {
    margin: 0 auto;
    padding: 0;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;

    li {
      list-style: none;
      padding: 16px 12px;
      border-top: 1px solid $border-color;

      &:first-child {
        border-top: 0;
      }

      @media(min-width: 768px) {
        padding: 16px 64px;
      }

      @media(min-width: 1024px) {
        padding: 24px;
      }
    }
  }
}

/**
Picture Block
 */

.picture-block {
  padding: 24px 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;

  @media(min-width: 768px) {
    padding: 48px 64px;
    grid-row-gap: 48px;
  }

  @media(min-width: 1024px) {
    padding: 48px 24px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  .fix-container {
    @media(min-width: 1440px) {
      padding: 0 24px;
    }
  }
}

/**
Text Cards
 */

.text-cards {
  .text-block {
    .columns2 {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  .info-grid {
    @media(min-width: 768px) and (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }

  p {
    margin: 0;
  }
}

/**
Text Bordered
 */

.text-bordered {
  width: 100%;
  margin: 0 auto;
  padding: 24px 12px;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  box-sizing: border-box;

  @media(min-width: 768px) {
    padding: 47px 24px;
  }

  @media(min-width: 1024px) {
    padding: 47px 83px 47px 24px;
  }

  p {
    margin: 0;
  }
}

/**
Text Background
 */

.text-background {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  padding: 12px 0;

  @media(min-width: 768px) {
    padding: 24px 0;
  }

  &__bg {
    background-color: $bg-color;
    padding: 12px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.primary {
      background-color: $primary-bg-color;
    }

    &.secondary {
      background-color: $secondary-bg-color;
    }

    @media(min-width: 768px) {
      padding: 24px;
      gap: 24px;
    }

    p {
      margin: 0;
    }
  }
}

/**
Bages
 */

.bages-list {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  .fix-container {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media(min-width: 768px) {
      padding: 24px;
      gap: 48px;
    }

    .badge {
      padding: 7px 16px;
      border-radius: 20px;
      display: flex;
      gap: 50px;
      background: $secondary-bg-color;

      span {
        font-family: $font-family-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        color: #110D1C;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}

/**
Small Cards
 */

.small-cards {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  .fix-container {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media(min-width: 768px) {
      padding: 24px;
      gap: 48px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media(min-width: 768px) {
      gap: 24px;
    }
  }

  &__row {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr;

    @media(min-width: 768px) {
      gap: 49px;
    }

  }

  &__col3 {
    @media(min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__col4 {
    @media(min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

/**
Small Card
 */

.small-card {
  position: relative;
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background: #EBF0F4;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #EBF0F4;
    position: absolute;
    bottom: -13px;
    right: 0;

    @media(min-width:768px) {
      width: 1px;
      height: 100%;
      right: -25px;
      bottom: unset;
      top: 0;
    }
  }

  &:last-child {
    &:after {
      content: none;
    }
  }

  &.primary {
    background: $primary-bg-color;
  }

  &.secondary {
    background: $secondary-bg-color;
  }

  &__title {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $font-family-title;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 14.5px;
    color: #5B5F6D;
  }

  &__number {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px
  }
}

